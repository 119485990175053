<template>
    
    <selected style="height: 4px;">
        <div class="hr1"></div>
<div class="css-1f8gpsx" >

  <!-- <div >  class="css-1xlhn9g " -->
    <div>
  <div class="MuiTabs-flexContainer css-k008qs" role="tablist">
  <button class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary Mui-selected css-1i27ufj" tabindex="0" type="button" role="tab" aria-selected="true">All<span class="MuiTouchRipple-root css-w0pj6f"></span></button>
  <button class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary css-1i27ufj" tabindex="-1" type="button" role="tab" aria-selected="false">Sats Names<span class="MuiTouchRipple-root css-w0pj6f"></span></button>
  <button class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary css-1i27ufj" tabindex="-2" type="button" role="tab" aria-selected="false">Bitmap<span class="MuiTouchRipple-root css-w0pj6f"></span></button>
  </div>
  </div>
  

 
 <div class=" css-bcctbf ">
  <div class="MuiFormControl-root css-13sljp9">
    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined css-1xskxc1" data-shrink="true" id="filter-select">Status</label>
       <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall  css-1oqfyky">
         <div tabindex="0" role="button" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="order-status-select" class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1y7k1sw">Listed</div>
            <input aria-hidden="true" tabindex="-1" class="MuiSelect-nativeInput css-1k3x8v3" value="open" data-ddg-inputtype="unknown"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-yop3gh" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
              <fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-nqlg3w">
                <legend class="css-14lo706"><span>Status</span></legend></fieldset></div></div>
                <div class="MuiFormControl-root css-13sljp9"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined css-1xskxc1" data-shrink="true" id="filter-select">Filter</label>
              <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall  css-1oqfyky"><div tabindex="0" role="button" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="filter-select" class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1y7k1sw">sub 1k</div>
            <input aria-hidden="true" tabindex="-1" class="MuiSelect-nativeInput css-1k3x8v3" value="k1" data-ddg-inputtype="unknown"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-yop3gh" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg><fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-nqlg3w">
          <legend class="css-14lo706"><span>Filter</span></legend></fieldset></div></div><div class="MuiFormControl-root css-13sljp9"><label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined css-1xskxc1" data-shrink="true" id="sorting-select">Sort</label>
        <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall  css-1oqfyky"><div tabindex="0" role="button" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="sorting-select" class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1y7k1sw">Number</div>
       <input aria-hidden="true" tabindex="-1" class="MuiSelect-nativeInput css-1k3x8v3" value="number" data-ddg-inputtype="unknown"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-yop3gh" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg><fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-nqlg3w">
     <legend class="css-14lo706"><span>Sort</span></legend></fieldset>
    </div>
   </div>
   </div>
 </div>

</Selected>
</template>
<script>


</script>

<style lang="scss" scoped>

.css-1f8gpsx {
    margin-bottom: 12px;
    display: flex;
    flex-flow: row wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    gap: 6px;
    background-color: rgb(32, 32, 32);
  }


.css-1f8gpsx {
  margin-bottom: 12px;
 padding: 8px;
  display: flex;
  flex-flow: row wrap;
  -moz-box-pack: justify;
  justify-content: space-between;
  gap: 6px;
  background-color: #123456;
  }

.css-13o7eu2 {
    display: block;
  }



.css-1xlhn9g {
  overflow: hidden;
  min-height: 48px;
  display: flex;
  padding-top: 8px;
  
}
element {
  overflow: hidden;
  margin-bottom: 0px;
}
.css-1anid1y {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
}
*, ::before, ::after {
  box-sizing: inherit;
}
.css-k008qs {
    display: flex;
  }
.css-1i27ufj.Mui-selected {
    color: #fcd535;
    font-size: 3.2rem;
    
  }

.css-1i27ufj {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: currentcolor none 0px;
  border: 0px none;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  //color: rgba(255, 255, 255, 0.7);
}

*, ::before, ::after {
  box-sizing: inherit;
}
.css-1i27ufj.Mui-selected {
  color: rgb(250, 4, 211);
  font-size: 1.2rem;
  }

.css-1i27ufj {
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);

}


.css-1i27ufj {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: currentcolor none 0px;
  border: 0px none;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.7);
}

.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
  
  
}

*, ::before, ::after {
  box-sizing: inherit;
}

.css-1i27ufj {
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

*, ::before, ::after {
  box-sizing: inherit;
}


*, ::before, ::after {
  box-sizing: inherit;
}


.css-1i27ufj {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: currentcolor none 0px;
  border: 0px none;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.7);
}


.hr1 {
        border: 0;
        height: 0.1057rem;
        background: #3d3f3f;
       
      }

*, ::before, ::after {
    box-sizing: inherit;
  }
.css-1anid1y {
  white-space: nowrap;
}

element {
  left: 0px;
  width: 90px;
}

.css-19tgd8k {
  position: absolute;
  height: 2px;
  bottom: 0px;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(250, 4, 211);
}

.css-1i27ufj {
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}


  .css-bcctbf {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    padding-top: 6px;
    background-color: #123456;
    
    }

.css-13sljp9 {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px none;
  vertical-align: top;
  
}

.css-bcctbf {
  white-space: nowrap;
}

.css-1xskxc1 {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
}

.css-bcctbf {
  white-space: nowrap;
}

.css-13sljp9 {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px none;
  vertical-align: top;
}

.css-bcctbf {
  white-space: nowrap;
}

.css-1xskxc1 {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
}

.css-1y7k1sw.css-1y7k1sw.css-1y7k1sw {
  padding-right: 32px;
}

.css-1y7k1sw {
  appearance: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px none;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  height: 1.4375em;
  margin: 0px;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 8.5px 14px;
    padding-right: 14px;
}

.css-1k3x8v3 {
  bottom: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

.css-yop3gh {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
  pointer-events: none;
  color: rgb(255, 255, 255);
}


.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

.css-nqlg3w {
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(255, 255, 255, 0.23);
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

.css-14lo706 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0px;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

.css-14lo706 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0px;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}
*, ::before, ::after {
  box-sizing: inherit;
}

.css-14lo706 > span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

*, ::before, ::after {
  box-sizing: inherit;
}
.css-14lo706 {
  font-size: 0.75em;
  visibility: hidden;
  white-space: nowrap;
}
.css-nqlg3w {
  text-align: left;
  pointer-events: none;
}
.css-1oqfyky {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(255, 255, 255);
  cursor: text;
}

</style>