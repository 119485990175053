<template>
    <div>
        <section class="ant-layout">
            <div class="header-view" data-v-396725c8="">
                <div class="ordswap-header" data-v-396725c8=""><a class="logo" data-v-396725c8=""><img class="text-logo"
                            src="/assets/logo-with-text-d9f19607.svg" data-v-396725c8=""></a>
                    <div class="header-nav" data-v-0e5000e8="" data-v-396725c8=""><a class="nav"
                            data-v-0e5000e8="">Market<!----></a><a class="nav active" data-v-0e5000e8="">Inscribe<!----></a>
                    </div>
                    <div class="header-menu" data-v-396725c8=""><button class="ant-btn ant-btn-primary ant-btn-lg ui-button"
                            type="button" data-v-396725c8=""><!----><span role="img" aria-label="wallet"
                                class="anticon anticon-wallet" data-v-396725c8=""><svg focusable="false" class=""
                                    data-icon="wallet" width="1em" height="1em" fill="currentColor" aria-hidden="true"
                                    viewBox="64 64 896 896">
                                    <path
                                        d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 464H528V448h312v128zm0 264H184V184h656v200H496c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h344v200zM580 512a40 40 0 1080 0 40 40 0 10-80 0z">
                                    </path>
                                </svg></span><span data-v-396725c8="">Wallet</span></button><button
                            class="ant-btn ant-btn-lg ui-button" type="button" data-v-396725c8=""><!---->
                            <div class="ant-space ant-space-horizontal ant-space-align-center" data-v-396725c8=""
                            style="gap: 8px;">
                            <div class="ant-space-item" style=""><img class="discord-icon"
                                    src="/assets/discord-fba26dab.svg" data-v-396725c8=""></div><!---->
                            <div class="ant-space-item"><span data-v-396725c8="">Discord</span></div><!---->
                        </div>
                    </button></div>
            </div>
        </div>
        <section class="ant-layout" data-v-153af1d2="">
            <main class="ant-layout-content content collection-container" data-v-153af1d2="">
                <div class="ant-row" data-v-153af1d2="" style="margin-left: -4px; margin-right: -4px; row-gap: 8px;">
                    <div class="ant-col ant-col-xs-24 ant-col-xs-order-0 ant-col-sm-24 ant-col-sm-order-0 ant-col-md-24 ant-col-md-order-0 ant-col-lg-24 ant-col-lg-order-0 ant-col-xl-24 ant-col-xl-order-0 ant-col-xxl-24 ant-col-xxl-order-0"
                        data-v-153af1d2="" style="padding-left: 4px; padding-right: 4px;">
                        <div class="ant-row" data-v-153af1d2=""
                            style="margin-left: -4px; margin-right: -4px; row-gap: 8px;">
                            <div class="ant-col ant-col-xs-24 ant-col-xs-order-0 ant-col-sm-24 ant-col-sm-order-0 ant-col-lg-24 ant-col-lg-order-0 ant-col-xl-24 ant-col-xl-order-0 ant-col-xxl-24 ant-col-xxl-order-0"
                                data-v-153af1d2="" style="padding-left: 4px; padding-right: 4px;">
                                <div class="slg" data-v-153af1d2="">
                                    <h1 class="ant-typography" data-v-153af1d2="">Bulk mint <br data-v-153af1d2="">
                                        Inscriptions to <!----><a
                                            class="ant-dropdown-link ant-dropdown-trigger chain-select"
                                            data-v-2a8af142=""><img class="chain-icon"
                                                src="/assets/bitcoin-6c10e88b.svg" data-v-2a8af142=""> BTC <span
                                                role="img" aria-label="down" class="anticon anticon-down"
                                                data-v-2a8af142=""><svg focusable="false" class="" data-icon="down"
                                                    width="1em" height="1em" fill="currentColor" aria-hidden="true"
                                                    viewBox="64 64 896 896">
                                                    <path
                                                        d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                    </path>
                                                </svg></span></a><!----></h1>
                                </div>
                                <div class="mint-uploader ui-upload" data-v-153af1d2=""><span data-v-153af1d2="">
                                        <div class="ant-upload ant-upload-drag"><span tabindex="0"
                                                class="ant-upload ant-upload-btn" role="button"><input type="file"
                                                    accept="image/*" multiple="" style="display: none;">
                                                <div class="upload-tip" data-v-153af1d2="">
                                                    <p class="ant-upload-drag-icon" data-v-153af1d2=""><span role="img"
                                                            aria-label="plus" class="anticon anticon-plus"
                                                            data-v-153af1d2=""><svg focusable="false" class=""
                                                                data-icon="plus" width="1em" height="1em"
                                                                fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <!-- <defs>
                                                                    <style></style>
                                                                </defs> -->
                                                                <path
                                                                    d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z">
                                                                </path>
                                                                <path
                                                                    d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z">
                                                                </path>
                                                            </svg></span></p>
                                                    <p class="ant-upload-text" data-v-153af1d2="" style="color: white;">
                                                        Click or drag files to this area to upload items </p>
                                                </div>
                                            </span></div>
                                        <div class="ant-upload-list ant-upload-list-picture-card"></div>
                                    </span></div>
                                <div class="ant-card ant-card-bordered app-card" data-v-153af1d2=""><!----><!---->
                                    <div class="ant-card-body">
                                        <div class="optimize-option" data-v-153af1d2="">0 selected <label
                                                class="ant-checkbox-wrapper ant-checkbox-wrapper-checked optimize-checkbox"
                                                data-v-153af1d2=""><span
                                                    class="ant-checkbox ant-checkbox-checked"><input type="checkbox"
                                                        class="ant-checkbox-input"><span
                                                        class="ant-checkbox-inner"></span></span><span> Optimize images
                                                </span></label></div>
                                    </div><!---->
                                </div>
                                <div class="ant-card ant-card-bordered app-card" data-v-153af1d2=""><!----><!---->
                                    <div class="ant-card-body">
                                        <div class="ant-row" data-v-153af1d2=""
                                            style="margin-left: -4px; margin-right: -4px; row-gap: 0px;">
                                            <div class="ant-col ant-col-16" data-v-153af1d2=""
                                                style="padding-left: 4px; padding-right: 4px;"><input
                                                    placeholder="Enter your collection id" type="text"
                                                    class="ant-input ant-input-lg ui-input" data-v-153af1d2=""></div>
                                            <div class="ant-col ant-col-8" data-v-153af1d2=""
                                                style="padding-left: 4px; padding-right: 4px;"><button
                                                    class="ant-btn ant-btn-lg ant-btn-block ui-button" type="button"
                                                    data-v-153af1d2=""
                                                    ant-click-animating-without-extra-node="false"><!----><span>Check
                                                        status</span></button></div>
                                        </div>
                                    </div><!---->
                                </div>
                                <div class="vue-recycle-scroller ready direction-vertical scroller" data-v-153af1d2="">
                                    <!--v-if-->
                                    <div class="vue-recycle-scroller__item-wrapper" style="min-height: 0px;"></div>
                                    <!--v-if-->
                                    <div class="resize-observer" tabindex="-1" data-v-b329ee4c=""><object
                                            aria-hidden="true" tabindex="-1" type="text/html"
                                            data="about:blank"></object></div>
                                </div>
                            </div>
                        </div>
                    </div><!---->
                </div>
            </main>
        </section>
    </section>
</div></template>

<script>
export default {
    setup() {


        return {}
    }
}
</script>

<style lang="scss" scoped></style>