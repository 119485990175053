<template>
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Bitfriendly - 404 | page not found</title>
    <link rel="shortcut icon" type="" href="https://script.viserlab.com/localcoins/demo/assets/images/logoIcon/favicon.png">
    <!-- bootstrap 4  -->
    <link rel="stylesheet" href="https://script.viserlab.com/localcoins/demo/assets/global/css/bootstrap.min.css">
    <!-- dashdoard main css -->
    <link rel="stylesheet" href="https://script.viserlab.com/localcoins/demo/assets/errors/css/main.css">
</head>

<body>

    <!-- error-404 start -->  
    <div class="error">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 text-center">
                    <img src="https://script.viserlab.com/localcoins/demo/assets/errors/images/error-404.png" alt="image">
                    <h2><b>404</b> Page not found</h2>
                    <p>page you are looking for doesn't exit or an other error ocurred <br> or temporarily unavailable.</p>
                    <a href="/" class="btn--base mt-4">Go to Home</a>
                </div>
            </div>
        </div>
    </div>
    <!-- error-404 end -->


</body>
   
  </template>
<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>
.css-white{
    color: white;
    padding: 15.5em 0.5em 0.5em;
    display: inline-block
}
</style>