<template>
    <div class="header-view" data-v-396725c8="" data-v-82b84cf5="">
        <div class="ordswap-header" data-v-396725c8="">
            <div class="logo" data-v-396725c8="">
                <span href="/" class="logo-text">BITFRIENDLY</span>
            </div>
            <div class="header-nav" data-v-0e5000e8="" data-v-396725c8="">
                <a class="nav" href="/" data-v-0e5000e8="">Market<!----></a><a class="nav active" href="/Inscribe"
                    data-v-0e5000e8="">Inscribe<!----></a>
            </div>
            <div class="header-menu" data-v-396725c8="">
                <a class="ant-btn ant-btn-primary ant-btn-lg ui-button" href="/wallet" type="button"
                    data-v-396725c8=""><!----><span role="img" aria-label="wallet" class="anticon anticon-wallet"
                        data-v-396725c8=""><svg focusable="false" class="" data-icon="wallet" width="1em" height="1em"
                            fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                            <path
                                d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 464H528V448h312v128zm0 264H184V184h656v200H496c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h344v200zM580 512a40 40 0 1080 0 40 40 0 10-80 0z">
                            </path>
                        </svg></span><span data-v-396725c8="">Wallet</span></a><button class="ant-btn ant-btn-lg ui-button"
                    type="button" data-v-396725c8="">
                    <!---->
                    <div class="ant-space ant-space-horizontal ant-space-align-center" data-v-396725c8="" style="gap: 8px">
                        <div class="ant-space-item" style="">
                            <img class="discord-icon" src="../assets/discord-fba26dab.svg" data-v-396725c8="" />
                        </div>
                        <!---->
                        <div class="ant-space-item">
                            <span data-v-396725c8="">Discord</span>
                        </div>
                        <!---->
                    </div>
                </button>
            </div>
        </div>
    </div>
    <section class="ant-layout">
        <section class="ant-layout app-container" >
            <div class="slg">
                <h1 class="ant-typography" >
                    Upload anything<br  />
                    to
                    <!----><a class="ant-dropdown-link ant-dropdown-trigger chain-select" ><img
                            class="chain-icon" src="../assets/bitcoin-6c10e88b.svg" data-v-2a8af142="" />
                        BTC
                        <span role="img" aria-label="down" class="anticon anticon-down" data-v-2a8af142=""><svg
                                focusable="false" class="" data-icon="down" width="1em" height="1em" fill="currentColor"
                                aria-hidden="true" viewBox="64 64 896 896">
                                <path
                                    d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                </path>
                            </svg></span></a><!---->
                </h1>
            </div>
            <div class="ant-tabs ant-tabs-top" data-v-82b84cf5="">
                <div role="tablist" class="ant-tabs-nav">
                    <!---->
                    <div class="ant-tabs-nav-wrap">
                        <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px)">
                            <div class="ant-tabs-tab ant-tabs-tab-active">
                                <div role="tab" aria-selected="true" class="ant-tabs-tab-btn" tabindex="0"
                                    id="rc-tabs-0-tab-file" aria-controls="rc-tabs-0-panel-file">
                                    File
                                </div>
                                <!---->
                            </div>
                            <div class="ant-tabs-tab">
                                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="0"
                                    id="rc-tabs-0-tab-text" aria-controls="rc-tabs-0-panel-text">
                                    Text
                                </div>
                                <!---->
                            </div>
                            <div class="ant-tabs-tab">
                                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="0"
                                    id="rc-tabs-0-tab-brc20" aria-controls="rc-tabs-0-panel-brc20">
                                    BRC-20
                                </div>
                                <!---->
                            </div>
                            <div class="ant-tabs-tab">
                                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="0"
                                    id="rc-tabs-0-tab-orc20" aria-controls="rc-tabs-0-panel-orc20">
                                    ORC-20
                                </div>
                                <!---->
                            </div>
                            <div class="ant-tabs-tab">
                                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabindex="0"
                                    id="rc-tabs-0-tab-domains" aria-controls="rc-tabs-0-panel-domains">
                                    .sats/.ltc/.doge
                                </div>
                                <!---->
                            </div>
                            <!---->
                            <div class="ant-tabs-ink-bar ant-tabs-ink-bar-animated" style="left: 0px; width: 46px"></div>
                        </div>
                    </div>
                    <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
                        <!----><button type="button" class="ant-tabs-nav-more" tabindex="-1" aria-hidden="true"
                            aria-haspopup="listbox" aria-controls="rc-tabs-0-more-popup" id="rc-tabs-0-more"
                            aria-expanded="false" style="visibility: hidden; order: 1">
                            <span role="img" aria-label="ellipsis" class="anticon anticon-ellipsis"><svg focusable="false"
                                    class="" data-icon="ellipsis" width="1em" height="1em" fill="currentColor"
                                    aria-hidden="true" viewBox="64 64 896 896">
                                    <path
                                        d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z">
                                    </path>
                                </svg></span></button><!---->
                    </div>
                    <!----><!---->
                </div>
                <div class="ant-tabs-content-holder">
                    <div class="ant-tabs-content ant-tabs-content-top">
                        <div role="tabpanel" tabindex="0" aria-hidden="false"
                            class="ant-tabs-tabpane ant-tabs-tabpane-active" data-v-82b84cf5="" id="rc-tabs-0-panel-file"
                            aria-labelledby="rc-tabs-0-tab-file">
                            <!---->
                        </div>
                        <div role="tabpanel" tabindex="-1" aria-hidden="true" class="ant-tabs-tabpane" data-v-82b84cf5=""
                            id="rc-tabs-0-panel-text" aria-labelledby="rc-tabs-0-tab-text" style="display: none">
                            <!---->
                        </div>
                        <div role="tabpanel" tabindex="-1" aria-hidden="true" class="ant-tabs-tabpane" data-v-82b84cf5=""
                            id="rc-tabs-0-panel-brc20" aria-labelledby="rc-tabs-0-tab-brc20" style="display: none">
                            <!---->
                        </div>
                        <div role="tabpanel" tabindex="-1" aria-hidden="true" class="ant-tabs-tabpane" data-v-82b84cf5=""
                            id="rc-tabs-0-panel-orc20" aria-labelledby="rc-tabs-0-tab-orc20" style="display: none">
                            <!---->
                        </div>
                        <div role="tabpanel" tabindex="-1" aria-hidden="true" class="ant-tabs-tabpane" data-v-82b84cf5=""
                            id="rc-tabs-0-panel-domains" aria-labelledby="rc-tabs-0-tab-domains" style="display: none">
                            <!---->
                        </div>
                    </div>
                </div>
            </div>
            <div class="ant-card ant-card-bordered mint-card app-card" data-v-82b84cf5="">
                <!----><!---->
                <div class="ant-card-body">
                    <div class="uploader" data-v-82b84cf5="">
                        <div class="collection-mint" data-v-82b84cf5="">
                            <button class="ant-btn ant-btn-primary ant-btn-lg ant-btn-block ui-button collection-mint-btn"
                                type="button" data-v-82b84cf5="">
                                <!----><span>Bulk inscribe</span>
                            </button>
                        </div>
                        <div class="ui-upload" data-v-14950c5e="" data-v-82b84cf5="" style="height: 150px">
                            <span data-v-14950c5e="">
                                <div class="ant-upload ant-upload-drag">
                                    <span tabindex="0" class="ant-upload ant-upload-btn" role="button"><input type="file"
                                            accept="" style="display: none" />
                                        <div class="upload-contianer" data-v-14950c5e="">
                                            <div class="cover" data-v-14950c5e="" style="height: 150px">
                                                <!----><!---->
                                                <div class="placeholder" data-v-14950c5e="">
                                                    <p class="ant-upload-drag-icon" data-v-14950c5e="">
                                                        <span role="img" aria-label="upload"
                                                            class="anticon anticon-upload upload-icon"
                                                            data-v-14950c5e=""><svg focusable="false" class=""
                                                                data-icon="upload" width="1em" height="1em"
                                                                fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <path
                                                                    d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z">
                                                                </path>
                                                            </svg></span>
                                                    </p>
                                                    <p class="ant-upload-text" data-v-14950c5e="">
                                                        Click or drag file to this area to upload
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div class="ant-upload-list ant-upload-list-text"></div>
                            </span>
                        </div>
                    </div>
                    <div class="textarea" data-v-82b84cf5="" style="display: none">
                        <div class="collection-mint" data-v-82b84cf5="">
                            <button class="ant-btn ant-btn-primary ant-btn-lg ant-btn-block ui-button collection-mint-btn"
                                type="button" data-v-82b84cf5="">
                                <!----><span>Bulk Inscribe your text!</span>
                            </button>
                        </div>
                        <textarea placeholder="What’s happening?" class="ant-input ui-input" data-v-82b84cf5="" style="
                height: 120px;
                min-height: 120px;
                max-height: 120px;
                overflow-y: hidden;
                resize: none;
              "></textarea>
                    </div>
                    <div class="textarea" data-v-82b84cf5="" style="display: none">
                        <div class="brc20-types" data-v-0bc565be="">
                            <div class="ant-radio-group ant-radio-group-outline ant-radio-group-large" data-v-0bc565be="">
                                <label class="ant-radio-wrapper ant-radio-wrapper-checked"><span
                                        class="ant-radio ant-radio-checked"><input type="radio" class="ant-radio-input"
                                            value="mint" /><span
                                            class="ant-radio-inner"></span></span><span>Mint</span></label><label
                                    class="ant-radio-wrapper"><span class="ant-radio"><input type="radio"
                                            class="ant-radio-input" value="deploy" /><span
                                            class="ant-radio-inner"></span></span><span>Deploy</span></label>
                            </div>
                        </div>
                        <form class="ant-form ant-form-horizontal brc20-form" data-v-0bc565be="">
                            <div class="ant-row ant-form-item" data-v-0bc565be="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Chain">Chain<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <!----><a class="ant-dropdown-link ant-dropdown-trigger chain-select"
                                                data-v-2a8af142=""><img class="chain-icon"
                                                    src="/assets/bitcoin-6c10e88b.svg" data-v-2a8af142="" />
                                                BTC
                                                <span role="img" aria-label="down" class="anticon anticon-down"
                                                    data-v-2a8af142=""><svg focusable="false" class="" data-icon="down"
                                                        width="1em" height="1em" fill="currentColor" aria-hidden="true"
                                                        viewBox="64 64 896 896">
                                                        <path
                                                            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                        </path>
                                                    </svg></span></a>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-0bc565be="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Protocol">Protocol<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <span class="protocol" data-v-0bc565be="">brc-20</span>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-0bc565be="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Tick">Tick<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <input placeholder='4 characters like "abcd"...' type="text"
                                                class="ant-input ui-input" data-v-0bc565be="" />
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-0bc565be="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Amount">Amount<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <div class="ant-input-number ui-input" data-v-0bc565be="">
                                                <div class="ant-input-number-handler-wrap">
                                                    <span unselectable="on" role="button" aria-label="Increase Value"
                                                        aria-disabled="false"
                                                        class="ant-input-number-handler ant-input-number-handler-up"><span
                                                            role="img" aria-label="up"
                                                            class="anticon anticon-up ant-input-number-handler-up-inner"><svg
                                                                focusable="false" class="" data-icon="up" width="1em"
                                                                height="1em" fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <path
                                                                    d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z">
                                                                </path>
                                                            </svg></span></span><span unselectable="on" role="button"
                                                        aria-label="Decrease Value" aria-disabled="false"
                                                        class="ant-input-number-handler ant-input-number-handler-down"><span
                                                            role="img" aria-label="down"
                                                            class="anticon anticon-down ant-input-number-handler-down-inner"><svg
                                                                focusable="false" class="" data-icon="down" width="1em"
                                                                height="1em" fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <path
                                                                    d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                                </path>
                                                            </svg></span></span>
                                                </div>
                                                <div class="ant-input-number-input-wrap">
                                                    <input autocomplete="off" role="spinbutton" aria-valuenow="1" step="1"
                                                        class="ant-input-number-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-0bc565be="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Repeat Mint">Repeat Mint<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <div class="ant-row" data-v-0bc565be="" style="row-gap: 0px">
                                                <div class="ant-col ant-col-12" data-v-0bc565be="">
                                                    <div tabindex="-1" class="ant-slider" data-v-0bc565be="">
                                                        <div class="ant-slider-rail"></div>
                                                        <div class="ant-slider-track"
                                                            style="left: 0%; right: auto; width: 0%"></div>
                                                        <div class="ant-slider-step"></div>
                                                        <!---->
                                                        <div class="ant-slider-handle" role="slider" tabindex="0"
                                                            aria-valuemin="1" aria-valuemax="500" aria-valuenow="1"
                                                            aria-disabled="false" style="
                                left: 0%;
                                right: auto;
                                transform: translateX(-50%);
                              "></div>
                                                        <div class="ant-slider-mark"></div>
                                                    </div>
                                                </div>
                                                <div class="ant-col ant-col-4" data-v-0bc565be="">
                                                    <div class="ant-input-number number-input" data-v-0bc565be=""
                                                        style="margin-left: 16px">
                                                        <div class="ant-input-number-handler-wrap">
                                                            <span unselectable="on" role="button"
                                                                aria-label="Increase Value" aria-disabled="false"
                                                                class="ant-input-number-handler ant-input-number-handler-up"><span
                                                                    role="img" aria-label="up"
                                                                    class="anticon anticon-up ant-input-number-handler-up-inner"><svg
                                                                        focusable="false" class="" data-icon="up"
                                                                        width="1em" height="1em" fill="currentColor"
                                                                        aria-hidden="true" viewBox="64 64 896 896">
                                                                        <path
                                                                            d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z">
                                                                        </path>
                                                                    </svg></span></span><span unselectable="on"
                                                                role="button" aria-label="Decrease Value"
                                                                aria-disabled="true"
                                                                class="ant-input-number-handler ant-input-number-handler-down ant-input-number-handler-down-disabled"><span
                                                                    role="img" aria-label="down"
                                                                    class="anticon anticon-down ant-input-number-handler-down-inner"><svg
                                                                        focusable="false" class="" data-icon="down"
                                                                        width="1em" height="1em" fill="currentColor"
                                                                        aria-hidden="true" viewBox="64 64 896 896">
                                                                        <path
                                                                            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                                        </path>
                                                                    </svg></span></span>
                                                        </div>
                                                        <div class="ant-input-number-input-wrap">
                                                            <input autocomplete="off" role="spinbutton" aria-valuemin="1"
                                                                aria-valuemax="500" aria-valuenow="1" step="1"
                                                                class="ant-input-number-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                        </form>
                        <div class="note" data-v-0bc565be="">
                            <span role="img" aria-label="info-circle" class="anticon anticon-info-circle"
                                data-v-0bc565be=""><svg focusable="false" class="" data-icon="info-circle" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                                    <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z">
                                    </path>
                                    <path
                                        d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z">
                                    </path>
                                </svg></span>
                            Note: inscribing transaction delivers the inscription to the
                            receiving address directly.
                        </div>
                    </div>
                    <div class="textarea" data-v-82b84cf5="" style="display: none">
                        <div class="orc20-types" data-v-7c07d549="">
                            <div class="ant-radio-group ant-radio-group-outline ant-radio-group-large" data-v-7c07d549="">
                                <label class="ant-radio-wrapper ant-radio-wrapper-checked"><span
                                        class="ant-radio ant-radio-checked"><input type="radio" class="ant-radio-input"
                                            value="mint" /><span
                                            class="ant-radio-inner"></span></span><span>Mint</span></label><label
                                    class="ant-radio-wrapper"><span class="ant-radio"><input type="radio"
                                            class="ant-radio-input" value="deploy" /><span
                                            class="ant-radio-inner"></span></span><span>Deploy</span></label>
                            </div>
                        </div>
                        <form class="ant-form ant-form-horizontal orc20-form" data-v-7c07d549="">
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Chain">Chain<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <!----><a class="ant-dropdown-link ant-dropdown-trigger chain-select"
                                                data-v-2a8af142=""><img class="chain-icon"
                                                    src="/assets/bitcoin-6c10e88b.svg" data-v-2a8af142="" />
                                                BTC
                                                <span role="img" aria-label="down" class="anticon anticon-down"
                                                    data-v-2a8af142=""><svg focusable="false" class="" data-icon="down"
                                                        width="1em" height="1em" fill="currentColor" aria-hidden="true"
                                                        viewBox="64 64 896 896">
                                                        <path
                                                            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                        </path>
                                                    </svg></span></a>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Protocol">Protocol<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <span class="protocol" data-v-7c07d549="">orc-20</span>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Id">Id<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <input type="text" class="ant-input ui-input" data-v-7c07d549="" />
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Tick">Tick<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <input placeholder='any characters like "abcd"...' type="text"
                                                class="ant-input ui-input" data-v-7c07d549="" />
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Amount">Amount<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <div class="ant-input-number ui-input" data-v-7c07d549="">
                                                <div class="ant-input-number-handler-wrap">
                                                    <span unselectable="on" role="button" aria-label="Increase Value"
                                                        aria-disabled="false"
                                                        class="ant-input-number-handler ant-input-number-handler-up"><span
                                                            role="img" aria-label="up"
                                                            class="anticon anticon-up ant-input-number-handler-up-inner"><svg
                                                                focusable="false" class="" data-icon="up" width="1em"
                                                                height="1em" fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <path
                                                                    d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z">
                                                                </path>
                                                            </svg></span></span><span unselectable="on" role="button"
                                                        aria-label="Decrease Value" aria-disabled="false"
                                                        class="ant-input-number-handler ant-input-number-handler-down"><span
                                                            role="img" aria-label="down"
                                                            class="anticon anticon-down ant-input-number-handler-down-inner"><svg
                                                                focusable="false" class="" data-icon="down" width="1em"
                                                                height="1em" fill="currentColor" aria-hidden="true"
                                                                viewBox="64 64 896 896">
                                                                <path
                                                                    d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                                </path>
                                                            </svg></span></span>
                                                </div>
                                                <div class="ant-input-number-input-wrap">
                                                    <input autocomplete="off" role="spinbutton" aria-valuenow="1" step="1"
                                                        class="ant-input-number-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                            <div class="ant-row ant-form-item" data-v-7c07d549="" style="row-gap: 0px">
                                <div class="ant-col ant-col-6 ant-form-item-label">
                                    <label class="" title="Repeat Mint">Repeat Mint<!----></label>
                                </div>
                                <div class="ant-col ant-form-item-control">
                                    <div class="ant-form-item-control-input">
                                        <div class="ant-form-item-control-input-content">
                                            <div class="ant-row" data-v-7c07d549="" style="row-gap: 0px">
                                                <div class="ant-col ant-col-12" data-v-7c07d549="">
                                                    <div tabindex="-1" class="ant-slider" data-v-7c07d549="">
                                                        <div class="ant-slider-rail"></div>
                                                        <div class="ant-slider-track"
                                                            style="left: 0%; right: auto; width: 0%"></div>
                                                        <div class="ant-slider-step"></div>
                                                        <!---->
                                                        <div class="ant-slider-handle" role="slider" tabindex="0"
                                                            aria-valuemin="1" aria-valuemax="500" aria-valuenow="1"
                                                            aria-disabled="false" style="
                                left: 0%;
                                right: auto;
                                transform: translateX(-50%);
                              "></div>
                                                        <div class="ant-slider-mark"></div>
                                                    </div>
                                                </div>
                                                <div class="ant-col ant-col-4" data-v-7c07d549="">
                                                    <div class="ant-input-number number-input" data-v-7c07d549=""
                                                        style="margin-left: 16px">
                                                        <div class="ant-input-number-handler-wrap">
                                                            <span unselectable="on" role="button"
                                                                aria-label="Increase Value" aria-disabled="false"
                                                                class="ant-input-number-handler ant-input-number-handler-up"><span
                                                                    role="img" aria-label="up"
                                                                    class="anticon anticon-up ant-input-number-handler-up-inner"><svg
                                                                        focusable="false" class="" data-icon="up"
                                                                        width="1em" height="1em" fill="currentColor"
                                                                        aria-hidden="true" viewBox="64 64 896 896">
                                                                        <path
                                                                            d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 00140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z">
                                                                        </path>
                                                                    </svg></span></span><span unselectable="on"
                                                                role="button" aria-label="Decrease Value"
                                                                aria-disabled="true"
                                                                class="ant-input-number-handler ant-input-number-handler-down ant-input-number-handler-down-disabled"><span
                                                                    role="img" aria-label="down"
                                                                    class="anticon anticon-down ant-input-number-handler-down-inner"><svg
                                                                        focusable="false" class="" data-icon="down"
                                                                        width="1em" height="1em" fill="currentColor"
                                                                        aria-hidden="true" viewBox="64 64 896 896">
                                                                        <path
                                                                            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                                                        </path>
                                                                    </svg></span></span>
                                                        </div>
                                                        <div class="ant-input-number-input-wrap">
                                                            <input autocomplete="off" role="spinbutton" aria-valuemin="1"
                                                                aria-valuemax="500" aria-valuenow="1" step="1"
                                                                class="ant-input-number-input" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                    </div>
                                    <!----><!---->
                                </div>
                            </div>
                        </form>
                        <div class="note" data-v-7c07d549="">
                            <span role="img" aria-label="info-circle" class="anticon anticon-info-circle"
                                data-v-7c07d549=""><svg focusable="false" class="" data-icon="info-circle" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                                    <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z">
                                    </path>
                                    <path
                                        d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z">
                                    </path>
                                </svg></span>
                            Note: inscribing transaction delivers the inscription to the
                            receiving address directly.
                        </div>
                    </div>
                    <div class="textarea" data-v-82b84cf5="" style="display: none">
                        <brc1155 data-v-82b84cf5=""></brc1155>
                    </div>
                    <div class="textarea" data-v-82b84cf5="" style="display: none">
                        <div class="brc20-types" data-v-adb8c73f="">
                            Inscribe to
                            <!----><a class="ant-dropdown-link ant-dropdown-trigger chain-select" data-v-2a8af142=""><img
                                    class="chain-icon" src="/assets/bitcoin-6c10e88b.svg" data-v-2a8af142="" />
                                BTC
                                <span role="img" aria-label="down" class="anticon anticon-down" data-v-2a8af142=""><svg
                                        focusable="false" class="" data-icon="down" width="1em" height="1em"
                                        fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                                        <path
                                            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                                        </path>
                                    </svg></span></a>
                        </div>
                        <div class="note" data-v-adb8c73f="">
                            Add your .sats/.ltc/.doge names below, each one on a new line.
                        </div>
                        <textarea placeholder="aaa.sats
bbb.ltc
ccc.doge
..." class="ant-input ui-input" data-v-adb8c73f="" style="
                height: 120px;
                min-height: 120px;
                max-height: 120px;
                overflow-y: hidden;
                resize: none;
              "></textarea>
                        <div class="note" data-v-adb8c73f="">
                            <span role="img" aria-label="info-circle" class="anticon anticon-info-circle"
                                data-v-adb8c73f=""><svg focusable="false" class="" data-icon="info-circle" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                                    <path
                                        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z">
                                    </path>
                                    <path
                                        d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z">
                                    </path>
                                </svg></span>
                            Note: Please choose the correct chain before inscribe your domains
                        </div>
                    </div>
                    <div class="optimize-option" data-v-82b84cf5="">
                        <label class="ant-checkbox-wrapper ant-checkbox-wrapper-checked optimize-checkbox"
                            data-v-82b84cf5=""><span class="ant-checkbox ant-checkbox-checked"><input type="checkbox"
                                    class="ant-checkbox-input" /><span class="ant-checkbox-inner"></span></span><span>
                                Optimize images </span></label>
                    </div>
                    <div class="optimize-option" data-v-82b84cf5="" style="display: none">
                        <label class="ant-checkbox-wrapper optimize-checkbox" data-v-82b84cf5=""><span
                                class="ant-checkbox"><input type="checkbox" class="ant-checkbox-input" /><span
                                    class="ant-checkbox-inner"></span></span><span> Bulk inscribe </span></label>
                    </div>
                    <div class="warning" data-v-82b84cf5="">
                        Note: Optimize images will change image size, if you are inscribing
                        a byte-perfect image please uncheck the option
                    </div>
                    <div class="address-input" data-v-82b84cf5="">
                        <div class="tip" data-v-82b84cf5="">
                            Address to Receive Inscription (optional):
                        </div>
                        <input placeholder="Leave blank if you don't have one, claim it later" type="text"
                            class="ant-input ant-input-lg ui-input" data-v-82b84cf5="" /><!---->
                    </div>
                    <div class="mint-action" data-v-82b84cf5="">
                        <div class="ant-row ant-row-center" type="flex" data-v-82b84cf5=""
                            style="margin-left: -8px; margin-right: -8px; row-gap: 16px">
                            <div class="ant-col ant-col-xs-24 ant-col-sm-12" data-v-82b84cf5=""
                                style="padding-left: 8px; padding-right: 8px">
                                <button class="ant-btn ant-btn-lg ant-btn-block ui-button" type="button" data-v-82b84cf5="">
                                    <!----><span>How does this work?</span>
                                </button>
                            </div>
                            <div class="ant-col ant-col-xs-24 ant-col-sm-12" data-v-82b84cf5=""
                                style="padding-left: 8px; padding-right: 8px">
                                <button class="ant-btn ant-btn-primary ant-btn-lg ant-btn-block ui-button" disabled=""
                                    type="button" data-v-82b84cf5="">
                                    <!----><span>Pay &amp; Inscribe</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!---->
            </div>
            <!---->
            <div class="ant-card ant-card-bordered app-card" data-v-82b84cf5="">
                <!----><!---->
                <div class="ant-card-body">
                    <div class="ant-row" data-v-82b84cf5="" style="margin-left: -4px; margin-right: -4px; row-gap: 0px">
                        <div class="ant-col ant-col-16" data-v-82b84cf5="" style="padding-left: 4px; padding-right: 4px">
                            <input placeholder="Enter your order id" type="text" class="ant-input ant-input-lg ui-input"
                                data-v-82b84cf5="" />
                        </div>
                        <div class="ant-col ant-col-8" data-v-82b84cf5="" style="padding-left: 4px; padding-right: 4px">
                            <button class="ant-btn ant-btn-lg ant-btn-block ui-button" type="button" data-v-82b84cf5="">
                                <!----><span>Get order info</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!---->
            </div>
            <!---->
        </section>
    </section>
</template>

<script>
export default {
    head: {
        script: [
            {
                type: "text/javascript",
                src: "../index.inscribe.js",
                async: true,
                body: true,
            }, // Insert in body
            // with shorthand
            { t: "application/ld+json", i: '{ "@context": "http://schema.org" }' },
            // ...
        ],
    },
};
</script>

<style lang="scss">
.my-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.logo-text {
    font-size: 2.2rem;
    padding-top: 55px;
    color: white;
}

/* CSS for larger screens */
.header-nav {
    display: flex;
}

.header-nav .nav {
    display: block;
}

/* CSS for smaller screens */
@media (max-width: 768px) {
    .header-nav {
        position: relative;
    }

    .header-nav .nav {
        display: none;
        position: absolute;
        //background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .header-nav:hover .nav {
        display: block;
    }

    .app-logo {
        width: 12px;
        height: 12px;
        border-radius: 32px;
        left: 0;
    }

    .text-logo {
        padding: 6px;
    }

    .logo-text {
        font-size: 1.2rem;
        padding-top: 35px;
        color: white;
    }

    .header-menu {
        display: block;
        //position: absolute;
        right: 0;
        width: 100%;
        /* Reduce to 80% of the original width */
        height: auto;
        align-items: center;
    }
}
</style>
