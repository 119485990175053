<template>
  <div class="app-footer">
    <div class="content-container">
      <footer class="footer-content">
        <p class="footer-text">
          Made by Moses Sechere:
          <a
            href="https://github.com/sech444"
          >Sech</a>
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footerbtc"
};
</script>

<style lang="scss" scoped>
.footer-content {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  padding-right: 20px;
}

.footer-text {
  font-size: 10px;
  text-align: right;
  color: #cccccc;
}
</style>

